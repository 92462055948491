import React, { useEffect } from "react";
import "../assets/minibar.css";
import { Link } from "react-router-dom";

const MiniBar = (props) => {
    const [preview, setPreview] = React.useState(null);
    useEffect(() => {
        if (props && props.preview) {
            var previewcan = props.preview;
            var mainurl = "https://cdn.sametkoch.com/showImage.php?path=";
            previewcan = previewcan.replace("https://cdn.sametkoch.com/assets", mainurl);
            setPreview(previewcan + "&resize=20");
        }
      }, [props]);
    return (
        <div className="minibar" id="gallery">
            <div className="minibar-title">
                <h4>KOC'S</h4>
                <h3>GALLERY</h3>
            </div>
            <div className="minibar-container scrolled-minibox">
                <Link to="/photos" className="minibar-box">
                    <div className="minibar-box-img">
                        <img
                            src={preview}
                            alt="gallery"
                        />
                    </div>
                    <div className="minibar-box-footer">
                        <h4>PHOTOS</h4>
                        <span className="minibar-box-footer-link">
                            View Album
                        </span>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default MiniBar;
