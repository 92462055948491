import React from 'react'
import '../assets/aboutme.css'

const AboutMe = (props) => {
  var [data, setData] = React.useState(null);
  var [description, setDescription] = React.useState(null);
  var [name, setName] = React.useState(null);
  React.useEffect(() => {
    if (props) {
      setData(props.description);
    }
  }, [props]);
  React.useEffect(() => {
    if (data) {
      setDescription(data.description);
      setName(data.name);
    }
  }
  , [data]);
  return (
    <div className='aboutme' id="about">
        <div className='aboutme-title'>
            <h3>MY NAME IS</h3>
            <h1>{name}</h1>
        </div>
        <div className='aboutme-content'>
            <div className='aboutme-textbox'>
                <p className='aboutme-text'>{description}</p>
            </div>
        </div>
    </div>
  )
}

export default AboutMe