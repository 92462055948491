import React, { useEffect, useState } from 'react';
import "../assets/home.css";
import MiniBar from '../components/MiniBar';
import AboutMe from '../components/AboutMe';
import Contact from '../components/Contact';

const Home = (props) => {
  const [name, setName] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [description, setDescription] = useState(null);
  const [contact, setContact] = useState(null);
  const [titles, setTitles] = useState(null);
  const [titlesReady, setTitlesReady] = useState(false);
  const [titleStyle, setTitleStyle] = useState(null);

  useEffect(() => {
    if (props.data) {
      var name = props.data.name;
      // add a new line after the first name for HTML
      name = name.replace(" ", "<br>");
      setName(name);
      setPreviewImage(props.data.previewImage);
      setDescription(props.data);
      setContact(props.data);
      setTitles(props.data.introTitles);
      setTitlesReady(true);
      //get length of titles
      var length = props.data.introTitles.length;
      //create css for animation
      var style = '';
      /*
      @keyframes slide-up{
                    0%{
                        transform: translateY(-24px);
                    }
                    33%{
                        transform: translateY(0px);
                    }
                    66%{
                        transform: translateY(+24px);
                    }
                    100%{
                        transform: translateY(-24px);
                    }
                }
                    */
      style = '@keyframes slide-up{';
      for (var i = 0; i < length; i++) {
        var percent = 100 / length;
        style += percent * i + '%{';
        style += 'transform: translateY(' + i * -1.3 + 'rem);';
        style += '}';
      }
      style += '}';
      setTitleStyle(style);
    }
  }, [props]);

  return (
    <div className='homepage'>
      <style>
        {titleStyle}
      </style>
      <div className='intro-component' id="home">
        <div className='intro-main'>
          {titlesReady ? (
            <h3 className='intro-title'>
              {titles.map((title, index) => {
                return <span key={index} className='intro-title-element'>{title}</span>;
              })}
            </h3>
          ) : null
          }
          <h1 className='intro-name' dangerouslySetInnerHTML={{ __html: name }}></h1>
        </div>
        <div className='intro-sub hidden-mobile'>
          <p>BASED IN <span className='rainbowtext'>PROVINCETOWN</span>, MA</p>
          <a href="#contact" className='intro-sub-contact-button'>CONTACT NOW</a>
        </div>
      </div>
      <MiniBar preview={previewImage} />
      <AboutMe description={description} />
      <Contact contact={contact} />
    </div>
  );
}

export default Home;
