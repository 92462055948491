import React from 'react'
import '../assets/preloader.css'
import Loading from '../assets/images/loading.gif'

const Preloader = () => {
  return (
    <div className='preloader'>
        <img src={Loading} alt='loading' />
    </div>
  )
}

export default Preloader