import React from 'react'
import "../assets/footer.css"

const Footer = () => {
  return (
    <div className='footer'>
        <p>
            © 2024 All rights reserved <br className='desktop-hidden' /> <span className='mobile-hidden'>| </span>
            <span>
                Designed by <a href="https://www.linkedin.com/in/emin-bilgen/">Emin Bilgen</a>
            </span>
        </p>
    </div>
  )
}

export default Footer