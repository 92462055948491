import React, { useState, useEffect } from "react";
import "../assets/photos.css";

const LeftArrow = () => {
    return (
        <svg
            fill="#000000"
            height="800px"
            width="800px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 490 490"
        >
            <g>
                <polygon
                    points="242.227,481.919 314.593,407.95 194.882,290.855 490,290.855 490,183.86 210.504,183.86 314.593,82.051 
		242.227,8.081 0,244.996 	"
                />
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
            </g>
        </svg>
    );
};

const RightArrow = () => {
    return (
        <svg
            fill="#000000"
            height="800px"
            width="800px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 490 490"
        >
            <g>
                <g>
                    <polygon
                        points="247.773,8.081 175.407,82.05 295.118,199.145 0,199.145 0,306.14 279.496,306.14 175.407,407.949 
			247.773,481.919 490,245.004 		"
                    />
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
            </g>
        </svg>
    );
};

const Photos = (props) => {
    const [data, setData] = useState(null);
    const [photoData, setPhotoData] = useState(null);
    const [photos, setPhotos] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("all");
    const [categories, setCategories] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState(null);
    const [currentPhoto, setCurrentPhoto] = useState(0); // Fix initial state
    const [viewer, setViewer] = useState(false);
    const [length, setLength] = useState(0);
    const [showPhotos, setShowPhotos] = useState(false);

    useEffect(() => {
        if (props.data) {
            setData(props.data);
        }
    }, [props]);

    useEffect(() => {
        if (data !== null && data.gallery !== null) {
            setPhotoData(data.gallery); // Tüm fotoğrafları sakla
            //her kategoriyi 1 kere ekle
            const allCategories = ["all"];
            data.gallery.forEach(photo => {
                if (!allCategories.includes(photo.category)) {
                    allCategories.push(photo.category);
                }
            });

            setCategories(allCategories);
    
            // Eğer kategori "all" değilse, seçilen kategoriyi filtrele
            if (selectedCategory !== "all") {
                const filteredPhotos = data.gallery.filter(photo => photo.category === selectedCategory);
                setSelectedPhotos(filteredPhotos);
            } else {
                // Tüm fotoğrafları göster
                setSelectedPhotos(data.gallery);
            }
        }
    }, [data, selectedCategory]);
    

    function showFilteredPhotos(category) {
        setSelectedCategory(category);
        if (category === "all") {
            setSelectedPhotos(data.gallery);
        } else {
            const filteredPhotos = data.gallery.filter(photo => photo.category === category);
            setSelectedPhotos(filteredPhotos);
        }
    }

    useEffect(() => {
        if (selectedPhotos) {
            setPhotos([]);
            selectedPhotos.forEach((photo, index) => {
                var link = photo.imageSrc.replace("https://cdn.sametkoch.com/assets", "https://cdn.sametkoch.com/showImage.php?path=") + "&resize=20";
                var linktwo = link.replace("https://cdn.sametkoch.com/assets", "https://cdn.sametkoch.com/showImage.php?path=") + "&resize=50";
                setPhotos(prevPhotos => [...prevPhotos, [photo, index, link, linktwo]]);
            });
            setShowPhotos(true);
            setCurrentPhoto(0);
        }
    }, [selectedPhotos]);
    

    useEffect(() => {
        if (selectedPhotos) {
            setPhotos([]); // Clear the current photos
            selectedPhotos.forEach((photo, index) => {
                var link = photo.imageSrc.replace("https://cdn.sametkoch.com/assets", "https://cdn.sametkoch.com/showImage.php?path=") + "&resize=20";
                var linktwo = link.replace("https://cdn.sametkoch.com/assets", "https://cdn.sametkoch.com/showImage.php?path=") + "&resize=50";
                setPhotos(prevPhotos => [...prevPhotos, [photo, index, link, linktwo]]);
            });
            setShowPhotos(true);
            setCurrentPhoto(0); // Reset current photo to the first one in the filtered list
            setLength(selectedPhotos.length); // Set the length to the number of selected photos
        }
    }, [selectedPhotos]);
    

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape") {
                closeViewer();
            }
        };
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);


    useEffect(() => {
        if (currentPhoto < 0) {
            setCurrentPhoto(photos.length - 1);
        } else if (currentPhoto > photos.length - 1) {
            setCurrentPhoto(0);
        }
    }, [currentPhoto]);


 
    const openViewer = (index) => {
        setCurrentPhoto(index);
        setViewer(true);
        document.body.classList.add("noscroll");
    };

    const closeViewer = () => {
        setViewer(false);
        document.body.classList.remove("noscroll");
    };
    return (
        <>
            <div className="categories">
                {categories.map((category, index) => (
                    <div
                        className={`category ${
                            selectedCategory === category ? "active" : ""
                        }`}
                        key={index}
                        onClick={() => showFilteredPhotos(category)}
                    >
                        {category}
                    </div>
                ))}
            </div>
            <div className="photos">

                {showPhotos === true ? (
                    photos.map((photo, index) => (
                        <div
                            className="photo-box"
                            key={photo[1]}
                            photoid={photo[1]}
                            style={{ backgroundImage: `url("${photo[2]}")` }}
                            onClick={() => openViewer(photo[1])}
                        ></div>
                    ))
                ) : (
                    <div>Loading...</div>
                )}
                <div
                    className="photo-viewer"
                    style={{ display: viewer ? "flex" : "none" }}
                    id={viewer ? "opened-viewer" : ""}
                >
                    <div className="viewer-overlay"></div>
                    <div
                        className="viewer-photos"
                        id="viewer-photos"
                    >
                        {showPhotos === true ? (
                            photos.map((photo, index) => (
                                <div
                                    className={`viewer-box ${
                                        currentPhoto === index ? "active" : ""
                                    }`}
                                    key={index}
                                >
                                    <img
                                        className="viewer-photo"
                                        src={photo[3]}
                                        alt={photo[1]}
                                    ></img>
                                </div>
                            ))
                        ) : (
                            <div>Loading...</div>
                        )}
                    </div>

                    <div className="viewer-counter">
                        {currentPhoto + 1} / {length}
                    </div>
                    <div className="viewer-close" onClick={closeViewer}>
                        <span className="line line-1"></span>
                        <span className="line line-2"></span>
                    </div>
                    <div
                        className="viewer-left hidden-mobile"
                        onClick={() => setCurrentPhoto(currentPhoto - 1)}
                    >
                        <LeftArrow />
                    </div>
                    <div
                        className="viewer-right hidden-mobile"
                        onClick={() => setCurrentPhoto(currentPhoto + 1)}
                    >
                        <RightArrow />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Photos;
