import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import axios from "axios";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Photos from "./pages/Photos";
import Preloader from "./components/Preloader";

export default function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get("https://api.sametkoch.com/landing/main")
      .then((response) => {
        setData(response.data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        setLoading(false); // Set loading to false even if there's an error
      });
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <Router>
      <div>
        <Header data={data} />
        <Routes>
          <Route path="/" element={<Home data={data} />} />
          <Route path="/photos" element={<Photos data={data} />} />
          <Route path="/home/:page" element={<Home data={data} />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
