import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../assets/header.css";

const Header = (props) => {
    var location = useLocation();
    var [scrollLocation, setScrollLocation] = useState(0);
    var [headerHidden, setHeaderHidden] = useState(false);
    var [scrollHeader, setScrollHeader] = useState(300);
    var [logoSrc, setLogoSrc] = useState("");
    var isMobileNavOpen = useState(false);
    useEffect(() => {
        
        switch (location.pathname) {
            case "/":
                window.scrollTo(0, 0);
                break;
            case "/photos":
                window.scrollTo(0, 0);
                break;
            default:
                //split by / and get the last element
                var path = location.pathname.split("/").pop();
                var element = document.getElementById(path);
                if (element) {
                    //then scroll to that element and after that scroll 129px up if header is not hidden
                    //take element location
                    var elementLoc = element.getBoundingClientRect().top;
                    if(headerHidden === false){
                        //take window scroll loc scroll more 129px to up
                        var scrollLoc = window.scrollY - 129;
                        elementLoc = elementLoc + scrollLoc;
                    }
                    window.scrollTo(0, elementLoc);
                    
                }
                break;
        }
    }, [location]);
    useEffect(() => {
        if (props.data) {
            setLogoSrc(props.data.logo);
        }
    }, [props]);
    //if on mobile, set scrollHeader to 100px
    useEffect(() => {
        if (window.innerWidth < 768) {
            setScrollHeader(100);
        }
    }, []);

    var hideHeader = () => {
        setHeaderHidden(true);
    };

    var showHeader = () => {
        setHeaderHidden(false);
    };

    window.addEventListener("scroll", () => {
        //if scrolled down and away from top 129px, hide header, else if scrolled up 30px, show header
        if (window.scrollY > scrollLocation + 129) {
            hideHeader();
        } else if (window.scrollY < scrollLocation - scrollHeader) {
            showHeader();
        }
        setScrollLocation(window.scrollY);
    });

    const openMobileNav = () => {
        if (isMobileNavOpen) {
            document
                .querySelector(".mobile-navbar")
                .classList.remove("navbar-hidden");

            document.querySelector("#header-trigger").classList.add("active");
            //add noscroll class to body to prevent scrolling
            document.body.classList.add("noscroll");
        } else {
            document
                .querySelector(".mobile-navbar")
                .classList.add("navbar-hidden");
            document
                .querySelector("#header-trigger")
                .classList.remove("active");
            //remove noscroll class to body to enable scrolling
            document.body.classList.remove("noscroll");
        }
        isMobileNavOpen = !isMobileNavOpen;
    };
    return (
        <div
            className={`header ${
                headerHidden ? "hidden header-main" : "header-main"
            }`}
        >
            <div className="header-container">
                <div className="header-parts">
                    <Link to="/">
                        <img className="header-logo" src={logoSrc} alt="logo" />
                    </Link>
                </div>
                <div className="header-parts flex-right">
                    <div
                        onClick={openMobileNav}
                        id="header-trigger"
                        className="header-navbar hidden-desktop"
                    >
                        <span className="header-menu-line first"></span>
                        <span className="header-menu-line middle"></span>
                        <span className="header-menu-line last"></span>
                    </div>
                    <div className="header-navbar hidden-mobile">
                        <div className="header-navbar-item">
                            <Link to="/">Home</Link>
                        </div>
                        <div className="header-navbar-item">
                            <Link to="/home/gallery">Gallery</Link>
                        </div>
                        <div className="header-navbar-item">
                            <Link to="/home/about">About</Link>
                        </div>
                        <div className="header-navbar-item">
                            <Link to="/home/contact">Contact</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-navbar navbar-hidden hidden-desktop">
                <div className="mobile-navbar-container">
                    <div className="mobile-navbar-item">
                        <Link to="/" onClick={openMobileNav}>
                            Home
                        </Link>
                    </div>
                    <div className="mobile-navbar-item">
                        <Link to="/home/gallery" onClick={openMobileNav}>
                            Gallery
                        </Link>
                    </div>
                    <div className="mobile-navbar-item">
                        <Link to="/home/about" onClick={openMobileNav}>
                            About
                        </Link>
                    </div>
                    <div className="mobile-navbar-item">
                        <Link to="/home/contact" onClick={openMobileNav}>
                            Contact
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
